import { getData, postData, deleteData } from './bffApiService';

export default {
  async getApplications(commonId) {
    return getData('api/v1/applications', { userId: commonId });
  },
  async getApplicationAndUserPermissions(data) {
    const url = `api/v1/applications/${data.applicationId}/users/${data.userId}/permissions`;
    return getData(url);
  },
  async getApplicationPermissions(applicationId) {
    const url = `api/v1/applications/${applicationId}/permissions`;
    return getData(url);
  },
  async createUserPermission({ appId, userId }) {
    const url = `api/v1/applications/${appId}/users/${userId}/permissions`;
    return postData(url);
  },
  async deleteUserPermission({ appId, userId }) {
    const url = `api/v1/applications/${appId}/users/${userId}/permissions`;
    return deleteData(url);
  },
};
