import axios from 'axios';

const options = {
  baseURL: process.env.VUE_APP_BFF_URL,
  headers: {
    'Content-Type': 'application/json'
  }
};

export const getData = async (url, params = null, paramsSerializer = null) => {
  try {
    const response = await axios.get(url, { ...options, params, paramsSerializer });
    return response;
  } catch (error) {
    return null;
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axios.post(url, data, options);
    return response;
  } catch (error) {
    return null;
  }
};

export const putData = async (url, data) => {
  try {
    const response = await axios.put(url, data, options);
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axios.delete(url, options);
    return response;
  } catch (error) {
    return null;
  }
};
