import { getData, postData, putData } from './bffApiService';

export default {
  async getTemplateVersion(data) {
    const url = `api/v1/templates/${data.templateId}/versions/${data.versionId}`;
    return getData(url);
  },
  async getTemplateVersions(data) {
    const url = `api/v1/templates/${data.templateId}/versions`;
    return getData(url);
  },
  async getTemplates(applicationId) {
    const url = `api/v1/applications/${applicationId}/templates`;
    return getData(url);
  },
  async createTemplate(data) {
    const url = `api/v1/applications/${data.applicationId}/templates`;
    return postData(url, data.template);
  },
  async updateTemplate(templateData) {
    const url = `api/v1/templates/${templateData.id}`;
    return putData(url, templateData);
  }
};
