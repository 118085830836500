import api from 'api-client';
import dateUtils from '../../../services/dateUtilities/dateUtilities';
import _ from 'lodash';
import newApi from '@/services/api/api';
import templateServices from '@/services/bff/bffTemplateServices';

const state = {
  items: [],
  currentTemplate: {},
  currentTemplateVersions: [],
  notificationHistory: [],
  isLoading: false,
  readReceipts: [],
  totalRecords: 0,
  totalReadReceiptRecords: 0
};
const mutations = {
  setTemplates(statePassThru, data) {
    if (!data.data) {
      statePassThru.items = [];
    } else {
      statePassThru.items = data.data;
    }
  },
  setTemplate(statePassThru, data) {
    statePassThru.currentTemplate = data.data;
    statePassThru.isLoading = false;
  },
  setAllTemplateVersions(statePassThru, data) {
    statePassThru.currentTemplateVersions = data;
  },
  createTemplate(statePassThru, data) {
    if (data.errorDetail) {
      throw new Error(`Error creating template: ${data.errorDetail}`);
    }
    if (data.data.id) {
      statePassThru.items.splice(0, 0, data.data);
      statePassThru.currentTemplate = data.data;
    }
  },
  updateTemplate(statePassThru, data) {
    statePassThru.currentTemplate = data;
  },
  deleteTemplate(statePassThru, data) {
    if (!data.errors) {
      statePassThru.items = _.remove(statePassThru.items, function(template) {
        return template.Id !== statePassThru.currentTemplate.Id;
      });
      statePassThru.currentTemplate = {};
    }
  },
  setHistory(statePassThru, data) {
    if (data && data.data) {
      const history = data.data.map((h) => {
        h.friendlyCreateDateTime = dateUtils.formatDateTimeToString(h.createDateTime, true);
        return h;
      });
      statePassThru.notificationHistory = history;
      statePassThru.totalRecords = data.totalRecords;
    } else {
      statePassThru.notificationHistory = [];
      statePassThru.totalRecords = 0;
    }
  },
  setIsLoading(statePassThru, data) {
    statePassThru.isLoading = data;
  },
  setReadReceipts(statePassThru, data) {
    if (data && data.data) {
      const readReceipts = data.data.map((h) => {
        h.friendlyLastReadDate = dateUtils.formatDateTimeToString(h.lastReadDate, true);
        return h;
      });
      statePassThru.readReceipts = readReceipts;
      statePassThru.totalReadReceiptRecords = data.totalRecords;
    } else {
      statePassThru.readReceipts = [];
      statePassThru.totalReadReceiptRecords = 0;
    }
  }
};

const actions = {
  createTemplate({ commit }, data) {
    return templateServices.createTemplate(data).then((res) => commit('createTemplate', res.data));
  },
  updateTemplate({ commit }, data) {
    return templateServices.updateTemplate(data).then((res) => {
      if (res.data.errors) {
        throw new Error(res.data.errors[0].message);
      }
      data.versionId = res.data.data.versionId;
      commit('updateTemplate', data);
    });
  },
  deleteTemplate({ commit }, data) {
    return api.templates(data).then((res) => {
      commit('deleteTemplate', res.data);
    });
  },
  getTemplates({ commit }, data) {
    return templateServices.getTemplates(data).then(res => commit('setTemplates', res.data));
  },
  getTemplateVersion({ commit }, data) {
    commit('setIsLoading', true);
    return templateServices.getTemplateVersion(data).then(res => commit('setTemplate', res.data));
  },
  async promoteTemplate({ commit }, data) {
    return api.templates(data).then((res) => {
      if (res.status === 200) {
        if (res.data && res.data.errors && res.data.errors[0].message === '403: Forbidden') {
          return { Message: 'You are not authorized to promote this template', StatusCode: 403 };
        }
        return { Message: 'Template Promoted', StatusCode: 200 };
      }
      // 500 Error not being handled in a catch because 403 will also catch but is a successful response.
      // I do not want this to trigger the catch statement in template-detail.js
      if (res.status === 500) {
        throw new Error('Internal Server Error');
      }
    });
  },
  async getAllTemplateVersions({ commit }, data) {
    const response = await templateServices.getTemplateVersions(data);
    const versions = response.data.data;
    const versionsWithUsers = versions.map((v) => {
      v.formattedVersionTime = `${v.friendlyVersionName} ${dateUtils.formatDateTimeToString(v.lastUpdateDateTime, true)}`;
      return v;
    });
    this.state.currentTemplateVersions = [];
    commit('setAllTemplateVersions', versionsWithUsers);

    return versionsWithUsers;
  },
  async getHistory({ commit }, data) {
    commit('setIsLoading', true);
    commit('setHistory');
    let response = await newApi.listHistory(data);

    if (!response) {
      commit('setIsLoading', false);
      return;
    }

    commit('setHistory', response.data);
    commit('setIsLoading', false);
  },
  async getReadReceipts({ commit }, data) {
    commit('setIsLoading', true);
    commit('setReadReceipts');

    let response = await newApi.listReadReceipts(data);

    if (!response) {
      commit('setIsLoading', false);
      return;
    }

    commit('setReadReceipts', response.data);
    commit('setIsLoading', false);
  }
};

const getters = {
  getCurrentTemplate: (state) => {
    return state.currentTemplate;
  },
  getTotalRecords: (state) => {
    return state.totalRecords;
  },
  getReadReceiptTotalRecords: (state) => {
    return state.totalReadReceiptRecords;
  }
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
