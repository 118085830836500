import bffApplicationServices from '@/services/bff/bffApplicationServices';

const state = {
  applicationPermissions: {},
  user: {}
};

const mutations = {
  setApplicationPermissions(statePassThru, data) {
    statePassThru.applicationPermissions = data.data.data;
  },
  setUserFromAuth(statePassThru, data) {
    statePassThru.user = data;
  }
};

const actions = {
  getApplicationPermissions({ commit }, data) {
    return bffApplicationServices.getApplicationPermissions(data)
      .then(res => commit('setApplicationPermissions', res));
  },
  getUserFromAuth({ commit }, data) {
    return commit('setUserFromAuth', data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
